.privacy {
  height: 100vh;
  width: 100vw;
  display: flex;
  align-items: flex-start; /* Aligns content to the top */
  justify-content: center;
  overflow-y: auto; /* Allows vertical scrolling */
  position: relative;
}

.fixed-buttons {
  position: fixed;
  margin-top: 0;
  width: 100%;
  display: flex;
  justify-content: space-between;
  padding: 0 20px;
  z-index: 2; /* Ensure the buttons are above the container */
}

.privacy__container {
  display: flex;
  flex-direction: column;
  width: 75%; /* Adjust the width as needed */
  text-align: center;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.5); /* Correct box-shadow property */
  padding: 30px;
  border-radius: 10px;
  background-color: #ffffffe8;
  margin-top: 15px;
  margin-bottom: 15px;
  position: relative;
  z-index: 1;
}

.bg-image {
  position: fixed; /* Fixes the image in the viewport */
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
  z-index: 0; /* Ensure it's behind the container */
}
