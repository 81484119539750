.imprint {
    height: 100vh;
    width: 100vw;
    display: flex;
    align-items: center;
    justify-content: center;
  }

.imprint__container {
    display: flex;
    flex-direction: column;
    text-align: center;
    background-color: #ffffffe8;
    box-shadow: 5px;
    padding: 30px;
    border-radius: 10px;
  }